body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.logo {
    width: 100%;
    padding: 21px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    height: 100px;
    border-bottom: solid 1px #ffffff2a;
    /* border-right: solid 1px #ffffff2a; */

    /* background: -webkit-linear-gradient(#eee, #333); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */

    /* background-color: #023399; */
    /* background-image: linear-gradient(132deg, #023399 0%, #3c81f6 100%); */
    color: #fff;

    /* 
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #3c81f6 100%); */
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #3c81f6;
    background-image: linear-gradient(132deg, #3c81f6 0%, #023399 100%);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
    background-image: linear-gradient(132deg, #3c81f6 0%, #023399 100%);
}

.my-primary {
    background-color: #3c81f6;
    background-image: linear-gradient(132deg, #3c81f6 0%, #023399 100%);
}

.site-badge-count-4 .ant-badge-count {
    color: #999;
    background-color: #fff;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.ant-layout,
.ant-layout-footer {
    background: #fafafa !important;
}

.ant-menu-item-icon {
    top: -2px;
    display: inline-block;
    position: relative;
}

.anticon {
    top: -3px;
    position: relative;
}

.seats svg {
    max-width: 100%;
    max-height: 100%;
}

/* .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  min-height: 200px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  min-height: 200px !important;
} */

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    min-height: 150px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    background-color: aliceblue;
}

.ant-spin-nested-loading,
.ant-spin-container {
    height: 100% !important;
    overflow: scroll;
}

td.ant-table-column-sort {
    background-color: #fff;
}
